import { Component, Input } from "@angular/core";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
  @Input() src: string;
  @Input() name: string;
  @Input() backgroundColor: string = "var(--app-red)";

  get initials(): string {
    if (!this.name) return "";
    const initials = this.name[0]?.charAt(0).toUpperCase();
    return initials;
  }
}
