import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as passwordHash from "js-sha512";
import { ErrorStateMatcher } from "@angular/material/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { MustMatch } from "./must-match.validator";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "src/app/core/authentication.service";
import { SharedService } from "src/app/shared/shared.service";
import { OrganizationService } from "../organization/organization.service";
import { TranslateService } from "@ngx-translate/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill", subscriptSizing: "dynamic" },
    },
  ],
})
export class ChangePasswordComponent implements OnInit {
  changepasswordForm: UntypedFormGroup;
  passwordHide = true;
  confirmPasswordHide = true;
  oldPasswordHide = true;
  errorMsg: any;
  showError: boolean = false;
  hideForm = false;
  loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private orgService: OrganizationService,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    let passwordregex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;
    this.changepasswordForm = this.fb.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(passwordregex),
            Validators.minLength(8),
          ],
        ],
        confirmPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(passwordregex),
            Validators.minLength(8),
          ],
        ],
        oldPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(passwordregex),
            Validators.minLength(8),
          ],
        ],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  changePasswordSubmit() {
    if (this.changepasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.showError = false;
    let hashedPassword = passwordHash.sha512(
      this.changepasswordForm.value.password
    );
    let hashedOldPassword = passwordHash.sha512(
      this.changepasswordForm.value.oldPassword
    );
    this.authService
      .resetPasswordAdminProvider(hashedPassword, hashedOldPassword)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.router.navigate(["/dashboard"]);
          this.translationService
            .get("Password has been changed successfully")
            .subscribe((text: string) => {
              this.orgService.showMessage(text, "success");
            });
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.showError = true;
          this.errorMsg = error.error.errors;
        }
        // (error: HttpErrorResponse) => {
        //  this.handleError(error)
        // }
      );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.message) {
          values.push(error.error.message[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error == "object") {
          errorMessage = error.error.message[0];
        } else {
          errorMessage = error.error;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
