<div class="forgot-container">
  <app-header></app-header>
  <div class="container-fluid mt-5">
    <div class="row">
      <div
        class="col-12 d-flex flex-column justify-content-center align-content-center"
      >
        <h4 class="heading">{{ "Forgot Password" | translate }}</h4>
        <div class="row justify-content-center align-items-center">
          <p class="subheading col-2">
            {{ "Please enter your email below" | translate }}
          </p>
        </div>

        <div
          class="col-12 mt-3 d-flex flex-column justify-content-center align-items-center"
        >
          <mat-card class="forgot-card">
            <mat-card-content>
              <form
                class="forgot-form"
                [formGroup]="forgotpasswordForm"
                (ngSubmit)="forgotPasswordSubmit()"
              >
                <p style="color: #46535b; font-weight: 600">
                  {{ "We will sent you the link to your mail." | translate }}
                </p>
                <mat-form-field class="example-full-width my-form-field">
                  <mat-label>{{ "Email" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    autocomplete="email"
                    formControlName="email"
                    required
                  />
                  <mat-error
                    *ngIf="forgotpasswordForm.get('email').hasError('required')"
                  >
                    {{ "Email is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="forgotpasswordForm.get('email').hasError('pattern')"
                  >
                    {{ "Please enter a valid email" | translate }}
                  </mat-error>
                </mat-form-field>
                <!-- <p class="msg" *ngIf="showSuccessMsg">
                {{
                  "The reset password link has been sent to your registered mail."
                    | translate
                }}
              </p> -->
                <p *ngIf="showErrorMsg" style="color: red; font-weight: bold">
                  {{ errorMsg }}
                </p>
                <div class="forgot-button-row">
                  <button
                    mat-raised-button
                    class="forgot-password-button"
                    [disabled]="loading || showSuccessMsg"
                  >
                    {{ "Continue" | translate }}
                  </button>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
