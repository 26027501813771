import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultipleErrorMessageComponent } from "./multiple-error-message/multiple-error-message.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { MessageComponent } from "../home/organization/message/message.component";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private snackBar: MatSnackBar) {}

  dismissMessage() {
    this.snackBar.dismiss();
  }

  showMessage(messages: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: messages,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }

  showMultipleErrors(message: any, panelClass: string) {
    this.snackBar.openFromComponent(MultipleErrorMessageComponent, {
      data: message,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  showErrorMessage(msg: string, panelClass: string = "single") {
    this.snackBar.openFromComponent(ErrorMessageComponent, {
      data: msg,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }
  formatNumber(num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }

  getMessages(error: any): any {
    let messages: string[] = [];

    if (error?.error?.message?.length && Array.isArray(error.error.message)) {
      messages = error.error.message.join(", ");
    } else {
      messages.push("Un error ha ocurrido");
    }

    return messages;
  }
}
