import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CredentialsService } from "src/app/core/credentials.service";
import { TooltipPosition } from "@angular/material/tooltip";
import { FormControl } from "@angular/forms";
import { AuthenticationService } from "src/app/core/authentication.service";
import { I18nService } from "src/app/core/i18n.service";
import { SharedService } from "../shared.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
})
export class HomeHeaderComponent implements OnInit {
  userName: string;

  tabActive = 0;
  providerName = "";
  links = [
    {
      name: "Dashboard",
      iconName: "dashboard",
      link: "/dashboard",
    },
    {
      name: "Miembros",
      iconName: "notes",
      link: "/members",
    },
  ];
  activeLink = this.links[0];

  constructor(
    private router: Router,
    private credService: CredentialsService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    if (this.router.url == "/members") {
      this.tabActive = 1;
    } else {
      this.tabActive = 0;
    }
    this.providerName =
      this.credService.credentials?.userDetails?.provider?.name || "";

    this.userName =
      this.credService.credentials.userDetails.firstName +
      " " +
      this.credService.credentials.userDetails.lastName;
  }

  gotoLandingPage() {
    if (this.credService.isAuthenticated()) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  testing(event) {
    if (event.index == 0) {
      this.navigateDashboard();
    }

    if (event.index == 1) {
      this.navigateMembers();
    }
  }

  changePassword() {
    this.router.navigate(["/change-password"]);
  }

  navigateMembers() {
    this.router.navigate(["/members"]);
  }

  navigateDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  logOut() {
    this.authService.logout().subscribe(
      (res: any) => {
        this.router.navigate(["/login"]);
      },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    );
  }

  changeLanguage(lang: string) {
    this.i18nService.language = lang;
    window.location.reload();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.message) {
          values.push(error.error.message[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error == "object") {
          errorMessage = error.error.message[0];
        } else {
          errorMessage = error.error;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
