<mat-toolbar style="padding-left: 0px">
  <div class="container" style="background-color: #ffffff">
    <mat-toolbar style="box-shadow: 0px 0px 0px">
      <img
        src="../../assets/images/header-logo.svg"
        width="90px"
        (click)="gotoLandingPage()"
        style="cursor: pointer"
      />
      <span class="example-spacer"></span>
      <mat-tab-group
        class="provider-name"
        (selectedTabChange)="testing($event)"
        [selectedIndex]="tabActive"
      >
        <mat-tab #dashboard>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="!dashboard.isActive">dashboard</mat-icon>
            <mat-icon
              *ngIf="dashboard.isActive"
              style="color: rgba(205, 37, 45, 1)"
              >dashboard</mat-icon
            >
            <span class="header-options-title"> Dashboard </span>
          </ng-template>
        </mat-tab>
        <mat-tab id="members" #members>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="!members.isActive">notes</mat-icon>
            <mat-icon
              *ngIf="members.isActive"
              style="color: rgba(205, 37, 45, 1)"
              >notes</mat-icon
            >
            <span class="header-options-title"> Miembros </span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div id="providerNameContainer" class="organization_name">
        <!-- [ngClass]=" -->
        <!-- providerName.length <= 24 -->
        <!-- ? 'organization_name_center' -->
        <!-- : 'organization_name' -->
        <!-- " -->
        <span class="organization-name-text">
          {{ providerName }}
        </span>
      </div>
      <span>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <app-avatar [name]="userName"></app-avatar>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="changePassword()">
            <mat-icon>
              <img class="w-75" src="assets/images/lock.svg" />
            </mat-icon>
            <span>{{ "Change Password" | translate }}</span>
          </button>
          <mat-divider></mat-divider>

          <button mat-menu-item (click)="logOut()">
            <mat-icon color="warn">
              <img class="w-75" src="assets/images/power.svg" />
            </mat-icon>
            <span style="color: red">{{ "Logout" | translate }}</span>
          </button>
        </mat-menu>
      </span>
    </mat-toolbar>
  </div>
</mat-toolbar>
